<template>
  <div>
    <general-table
      ref="countriesTable"
      :api-url="apiUrl"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="false"
      :view-content="false"
      :add-type="addType"
      :columns="columns"
      :type="type"
      :selectable="false"
    >
      <template #cell(edit_assign_date_and_eng)="data">
        <slot
          name="show"
          v-bind="data"
        >
          <div class="text-center">
            <b-btn
              style="font-size: smaller; text-wrap: nowrap; background-color: #0564f2!important; border: 1px solid #0564f2!important;"
              @click="
                $router.push({
                  name: 'assign-date-and-eng',
                  params: { id: data.item.id }
                })
              "
            >
              Edit Assign Date And Engineers
            </b-btn>
          </div>
        </slot>
      </template>
      <template #cell(show_info_assign)="data">
        <slot
          name="info"
          v-bind="data"
        >
          <div class="text-center">
            <span title="View">
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'show-audit',
                    params: { id: data.item.id }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'audit/assigned',
      // addType: "Add City",
      // addComponentName: "add-city",
      // editComponent: "edit-city",
      // viewContent: true,
      type: 'page',
      partners: [],
      filter: {},
      types: '',
      columns: [
        { key: 'id', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'days_require', label: 'Days Require' },
        { key: 'location', label: 'Location' },
        { key: 'date', label: 'Date' },
        { key: 'assigned_at', label: 'Assigned At' },
        { key: 'survialnce_only', label: 'Survialnce Only' },
        { key: 'edit_assign_date_and_eng', label: 'Edit Assign Date And Eng' },
        { key: 'show_info_assign', label: 'Show Info' },

        { key: 'actions' },
      ],
    }
  },

  computed: {
    apiUrl() {
      let base = 'audit/assigned?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }
      // console.log(base.slice(0, -1))
      return base.slice(0, -1)
    },
  },
  // mounted() {
  //   console.log('Count in mounted:', this.role)
  // },

  methods: {
    filterTypes(type) {
      const filteredType = this.status.filter(item => item.text !== type)
      this.types = filteredType
    },
  },
}
</script>

<style></style> --> -->
